import React from 'react'
import Layout from '../../shared/layout/Layout'

export default function Blog() {
  return (
    <Layout>
        <p className='p-5'>Blogs</p>
    </Layout>
  )
}
